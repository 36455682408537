import React from 'react'
import { PaymentMethodButton } from './styles'
import { usePosStore } from 'state/local/posReceiptStore'
import { TReceiptStore } from '../ReceiptDetailsModal'
import { getPaymentMethod } from 'src/helpers'

type PaymentOptionButtonProps = {
  isSelected?: boolean
  isDisabled?: boolean
  icon?: any
  text?: string
  onPress?: () => void
}
const PaymentOptionButton = (props: PaymentOptionButtonProps) => {
  const { isSelected, isDisabled, icon, text, onPress } = props
  return (
    <PaymentMethodButton
      isSelected={isSelected}
      onClick={!isDisabled ? onPress : () => {}}
      isDisabled={isDisabled}
    >
      {icon}
      <p style={{ marginTop: 5 }}>{text}</p>
    </PaymentMethodButton>
  )
}

export const PaymentMethodSelector = (props: {
  isDisabled: boolean
  selectOption: (val: any) => void
}) => {
  const receipt = usePosStore(store => store.pos.receipt) as TReceiptStore
  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)

  const enabledPaymentMethods =
    Object.entries(selectedPosDevice || {})
      ?.filter(([key, value]) => key?.startsWith('payment_') && value === true)
      ?.map(([key]) => {
        const paymentMethod = key?.replace('payment_', '')
        return getPaymentMethod(paymentMethod)
      })
      ?.sort((a, b) => a.sequence - b.sequence) || []

  const selectedMethod = receipt?.paymentMethod as
    | 'CASH'
    | 'CARD'
    | 'WIRE'
    | 'VIVA_WALLET'
    | 'OTHER'
  const { isDisabled, selectOption } = props
  return (
    <div>
      <p style={{ fontSize: 14, fontWeight: 500 }}>Način plaćanja</p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          marginTop: 8,
          gap: '10px',
        }}
      >
        {enabledPaymentMethods.map(pm => {
          return (
            <PaymentOptionButton
              onPress={() => {
                selectOption(pm?.id)
              }}
              isDisabled={isDisabled}
              text={pm?.name}
              icon={<pm.icon />}
              isSelected={selectedMethod === pm?.id}
            />
          )
        })}
      </div>
    </div>
  )
}
