import create from 'zustand'
import { nanoid } from 'nanoid'
import { persist } from 'zustand/middleware'
import produce from 'immer'
import { groupBy, sumBy } from 'lodash'
import { apolloClient } from 'src/Apollo'
import {
  PosJournalPrintJobDocument,
  PosJournalPrintJobQuery,
  PosJournalPrintJobQueryVariables,
  PosReceipt,
  PosReceiptPrintJobDocument,
  PosReceiptPrintJobQuery,
  PosTestPrintJobQuery,
  PosTestPrintJobDocument,
  PosInfoPrintJobQuery,
  PosInfoPrintJobQueryVariables,
  PosInfoPrintJobDocument,
} from 'state/graphql'
import { assert } from 'sdk/assert'
import {
  formatPriceNumberWithDelimiter,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import * as JSPM from 'jsprintmanager'

export interface IPosReceiptClient {
  id: string
  address?: string
  city?: string
  firstName?: string
  lastName?: string
  vatId?: string
  fullName?: string
  zipCode?: string
}
export type PaymentMethod = 'CASH' | 'CARD' | 'WIRE' | 'OTHER'
export interface IPosReceiptItem {
  id: string
  serviceId?: string
  productId?: string
  giftCardId?: string
  itemDescription: string
  quantity: number
  price: number
  finalPrice: number
  discount: number
  discountAmount: number
  total: number
  vatRate: number
  discountType?: 'percent' | 'amount'
  serviceType?: string
  stockCount?: number
  paymentRequestId?: string
  paymentRequestReceiptNo?: string | null
  resourceBookingServiceId?: string
}
export interface IPosReceiptVatItem {
  vatRate: number
  baseAmount: number
  vatAmount: number
}
export interface IPosReceipt {
  id: string
  client?: any
  totalAmount: number
  totalVatAmount: number
  paymentMethod?: PaymentMethod
  items: IPosReceiptItem[]
  vatItems: IPosReceiptVatItem[]
  selectedItem?: IPosReceiptItem
  receiptType: 'RECEIPT' | 'CANCELLATION'
  originalReceiptId?: string
  appointmentId?: string
  resourceBookingId?: string
  receiptComment?: string | null
  tipAmount?: number | null
  tipPaymentMethod?: PaymentMethod
  tipFiscalized?: boolean | null
}
export interface IPosDevice {
  id: string
  orgId: string
  locationId: string
  fisDeviceId: string
  fisLocationId: string
  defaultDepositAmount?: number
  defaultOperatorId?: string
  storeAddress?: any
  storeCity?: any
  storeIban?: any
  storeName?: any
  storeVatId?: any
  storeZip?: any
  deviceName?: any
  printerName?: any
  printerCols?: any
  printerBrand?: string | null
  printerModel?: string | null
  encoding?: string | null
  codeTable?: number | null
  characterSet?: number | null
  printerType?: string | null
  payment_CARD?: boolean
  payment_CASH?: boolean
  payment_WIRE?: boolean
  payment_VIVA_WALLET?: boolean
  payment_OTHER?: boolean
}
export interface IPosJournal {
  id: string
  deviceId: string
  locationId: string
  orgId: string
  date: Date
  cashDepositAmount: number
  totalCreditAmount: number
  totalDebitAmount: number
  cashBalanceAmount: number
  closingBalanceAmount: number
  creditCardBalanceAmount?: number
  fisDeviceId: string
  fisLocationId: string
  currencyId: string
  currencyCode?: string
  createdAt: Date
  journalNumber: string
  status: string
  closedAt?: Date
  printJobContent?: string
  receiptCount?: number
  canceledReceiptCount?: number
  print_storeName?: string
  print_storeAddress?: string
  print_storeZip?: string
  print_storeCity?: string
  print_storeVatId?: string
}
type PosState = {
  isInitialized: boolean
  receipt: IPosReceipt
  selectedItem?: IPosReceiptItem
  selectedOperatorId?: string
  activeJournal?: IPosJournal | null
  selectedPosDevice?: IPosDevice | null
}
type TAppState = {
  pos: PosState
}

const createDraftReceipt = (
  receiptType: 'RECEIPT' | 'CANCELLATION'
): IPosReceipt => ({
  id: nanoid(),
  items: <IPosReceiptItem[]>[],
  paymentMethod: <PaymentMethod | undefined>undefined,
  totalAmount: 0,
  totalVatAmount: 0,
  vatItems: [],
  receiptType: receiptType,
  client: <any | undefined>undefined,
})

JSPM.JSPrintManager.auto_reconnect = false
JSPM.JSPrintManager.start()

let jspmConnectAttempts = 0
if (JSPM.JSPrintManager.WS) {
  JSPM.JSPrintManager.WS.onStatusChanged = function () {
    const wsStatus = JSPM.JSPrintManager.websocket_status
    if (wsStatus == JSPM.WSStatus.Open) {
      console.log('JSPM is running')
    } else if (
      wsStatus === JSPM.WSStatus.Closed ||
      wsStatus === JSPM.WSStatus.Blocked
    ) {
      jspmConnectAttempts++

      if (jspmConnectAttempts > 2) {
        JSPM.JSPrintManager?.stop()
        console.log('JSPM failed to connect,stopping')
      } else {
        JSPM.JSPrintManager?.start()
      }
    }
  }
}
const base64ToArrayBuffer = base64 => {
  var binaryString = atob(base64)
  var bytes = new Uint8Array(binaryString.length)
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

const createCancellationReceipt = (receipt: PosReceipt): IPosReceipt => {
  return {
    id: nanoid(),
    items: receipt?.items?.map(x => {
      return {
        discount: x?.discountAmount,
        discountAmount: x?.discountAmount,
        finalPrice: x?.totalAmount! / x?.qty!,
        /* finalPrice: roundNumberWithTwoDecimals(
          x?.price! * (1 - x?.discountRate!)
        ), */
        itemDescription: x?.itemDescription,
        price: x?.price,
        quantity: x?.qty! * -1,
        serviceId: x?.serviceId,
        productId: x?.productId,
        giftCardId: x?.giftCardId,
        total: x?.totalAmount! * -1,
        vatRate: x?.vatRate! * 100,
        paymentRequestId: x?.paymentRequestId,
        paymentRequestReceiptNo: x?.paymentRequestReceiptNo,
      }
    }) as IPosReceiptItem[],
    paymentMethod: receipt?.paymentMethod as PaymentMethod,
    receiptType: 'CANCELLATION',
    receiptComment: receipt?.receiptComment,
    resourceBookingId: receipt?.resourceBookingId,
    client: receipt?.client as any,
    // calculated in function calculateTotal
    totalAmount: 0,
    totalVatAmount: 0,
    vatItems: [],
    tipAmount: receipt?.tipAmount * -1,
    tipPaymentMethod: receipt?.tipPaymentMethod as PaymentMethod,
  }
}

const updateState = (state: (s: TAppState) => void) => {
  setState(produce(state))
}

export const usePosStore = create<TAppState>()(
  persist(
    () =>
      ({
        pos: {
          isInitialized: false,
          receipt: createDraftReceipt('RECEIPT'),
          selectedItem: undefined,
        },
      }) as TAppState,
    {
      name: 'appStore-storage',

      partialize: state => {
        return {
          pos: {
            selectedOperatorId: state.pos.selectedOperatorId,
            selectedPosDevice: state.pos.selectedPosDevice,
            activeJournal: state.pos.activeJournal,
          },
        }
      },
    }
  )
)

const calculateTotal = receipt => {
  const totalAmount = sumBy(receipt.items, function (item: IPosReceiptItem) {
    return parseFloat(
      //@ts-ignore
      formatPriceNumberWithDelimiter(item.total)?.replace(',', '')
    )
  })

  const groupedByVatRate = groupBy(receipt.items, 'vatRate')
  let totalVatAmount = 0
  const vatItems: IPosReceiptVatItem[] = []
  Object.keys(groupedByVatRate).forEach(vatRate => {
    const rate = parseFloat(vatRate) / 100

    const vatRateItems = groupedByVatRate[vatRate]
    const vatRateTotal = sumBy(vatRateItems, 'total')
    totalVatAmount += roundNumberWithTwoDecimals(
      vatRateTotal * (1 - 1 / (1 + rate))
    )
    vatItems.push({
      vatRate: rate,
      vatAmount: totalVatAmount,
      baseAmount: vatRateTotal - totalVatAmount,
    })
  })

  return { ...receipt, totalAmount, totalVatAmount, vatItems }
}

const { setState, getState } = usePosStore

export const PosReceiptStore = {
  initPosStore: () => {
    updateState(state => {
      state.pos = {
        receipt: createDraftReceipt('RECEIPT'),
        selectedItem: undefined,
        selectedOperatorId: state.pos.selectedOperatorId,
        selectedPosDevice: state.pos.selectedPosDevice,
        activeJournal: state.pos.activeJournal,
        isInitialized: true,
      }
    })
  },
  // used for initializing cancellation receipt
  initPosStoreReceipt: receipt => {
    updateState(state => {
      state.pos = {
        receipt: createCancellationReceipt(receipt),
        selectedItem: undefined,
        selectedOperatorId: state.pos.selectedOperatorId,
        selectedPosDevice: state.pos.selectedPosDevice,
        activeJournal: state.pos.activeJournal,
        isInitialized: true,
      }
    })

    updateState(state => {
      state.pos.receipt = calculateTotal(state.pos.receipt)
    })
  },
  selectPosDevice: (device: IPosDevice) => {
    updateState(state => {
      state.pos.selectedPosDevice = { ...device }
    })
  },
  setActiveJournal: (journal: IPosJournal) => {
    updateState(state => {
      state.pos.activeJournal = journal ? { ...journal } : null
    })
  },
  selectClient: client => {
    updateState(state => {
      state.pos.receipt = { ...state.pos.receipt, client }
    })
  },
  clearClient: () => {
    updateState(state => {
      state.pos.receipt = { ...state.pos.receipt, client: undefined }
    })
  },
  setReceiptComment: (comment: string) => {
    updateState(state => {
      state.pos.receipt = { ...state.pos.receipt, receiptComment: comment }
    })
  },
  setReceiptResourceBookingId: (resourceBookingId: string) => {
    updateState(state => {
      state.pos.receipt = {
        ...state.pos.receipt,
        resourceBookingId,
      }
    })
  },
  selectPaymentMethod: (paymentMethod: PaymentMethod) => {
    const posReceiptData = getState().pos.receipt
    updateState(state => {
      state.pos.receipt = {
        ...posReceiptData,
        paymentMethod: paymentMethod,
        tipAmount: paymentMethod === 'WIRE' ? 0 : posReceiptData.tipAmount,
      }
    })
  },
  selectOperator: (operatorId: string) => {
    updateState(state => {
      state.pos.selectedOperatorId = operatorId
    })
  },
  addItem: (item: {
    price: number
    discountAmount: number
    discountRate: number
    vatRate: number
    itemDescription: string
    discountType?: any
    serviceId?: string
    productId?: string
    giftCardId?: string
    quantity?: number
    total?: number
    finalPrice?: number
    serviceType?: string
    stockCount?: number
    resourceBookingServiceId?: string
    paymentRequestId?: string
    paymentRequestReceiptNo?: string | null
  }) => {
    const receipt = getState().pos.receipt
    const updatedReceipt: IPosReceipt = {
      ...receipt,
      items: [
        ...receipt?.items,
        {
          id: nanoid(),
          serviceId: item.serviceId,
          productId: item.productId,
          giftCardId: item.giftCardId,
          itemDescription: item.itemDescription,
          quantity: item.quantity ? item.quantity : 1,
          price: item.price,
          discount:
            item.discountType === 'percent'
              ? item.discountRate || 0
              : item.discountAmount || 0,
          discountAmount: item.discountAmount ? item.discountAmount : 0,
          total: item.total || item.total === 0 ? item.total : item.price,
          vatRate: item.vatRate,
          discountType: item.discountType ? item.discountType : 'percent',
          finalPrice:
            item.finalPrice || item.finalPrice === 0
              ? item.finalPrice
              : item.price,
          serviceType: item.serviceType,
          stockCount: item.stockCount,
          paymentRequestId: item.paymentRequestId,
          paymentRequestReceiptNo: item.paymentRequestReceiptNo,
          resourceBookingServiceId: item.resourceBookingServiceId,
        },
      ],
    }
    updateState(state => {
      state.pos.receipt = calculateTotal(updatedReceipt)
    })
  },
  selectItem: (id: string) => {
    updateState(state => {
      state.pos.selectedItem = state.pos.receipt.items.find(e => e.id === id)
    })
  },
  removeItem: (id: string) => {
    updateState(state => {
      state.pos.receipt.items = state.pos.receipt.items.filter(e => e.id !== id)
      state.pos.receipt = { ...calculateTotal(state.pos.receipt) }
    })
  },
  clearItems: () => {
    updateState(state => {
      state.pos.receipt = { ...state.pos.receipt, items: [] }
    })
  },
  setTipAmount: (amount?: number | null) => {
    updateState(state => {
      state.pos.receipt.tipAmount = amount
    })
  },
  setTipPaymentMethod: (method?: PaymentMethod) => {
    updateState(state => {
      state.pos.receipt.tipPaymentMethod = method
    })
  },
  incrementItemQty: () => {
    updateState(state => {
      if (!state.pos.selectedItem) {
        return
      } else {
        const selectedItem = state.pos.selectedItem
        const qty = selectedItem?.quantity + 1
        const discountAmount =
          selectedItem.discountType === 'percent'
            ? qty * selectedItem.price * (selectedItem.discount / 100)
            : selectedItem.discount

        const finalPrice =
          selectedItem.discountType === 'percent'
            ? selectedItem.finalPrice
            : selectedItem.price > 0
            ? (selectedItem.price * qty - discountAmount) / qty
            : 0

        state.pos.selectedItem = {
          ...selectedItem,
          quantity: qty,
          finalPrice,
          total: finalPrice * qty,
          discountAmount,
        }
      }
    })
  },
  decrementItemQty: () => {
    updateState(state => {
      if (!state.pos.selectedItem) {
        return
      } else {
        const selectedItem = state.pos.selectedItem
        const qty = selectedItem?.quantity - 1
        const discountAmount =
          selectedItem.discountType === 'percent'
            ? qty * selectedItem.price * (selectedItem.discount / 100)
            : selectedItem.discount

        const finalPrice =
          selectedItem.discountType === 'percent'
            ? selectedItem.finalPrice
            : selectedItem.price > 0
            ? (selectedItem.price * qty - discountAmount) / qty
            : 0

        state.pos.selectedItem = {
          ...selectedItem,
          quantity: qty,
          finalPrice,
          total: finalPrice * qty,
          discountAmount,
        }
      }
    })
  },
  changeQuantity: (quantity: number) => {
    updateState(state => {
      if (!state.pos.selectedItem) {
        return
      } else {
        const selectedItem = state.pos.selectedItem
        state.pos.selectedItem = {
          ...selectedItem,
          quantity: quantity,
          total: selectedItem.finalPrice * quantity,
          discountAmount:
            selectedItem.discountType === 'percent'
              ? quantity * selectedItem.price * (selectedItem.discount / 100)
              : selectedItem.discount,
        }
      }
    })
  },
  setSelectedItemDiscountType: (discountType?: 'percent' | 'amount') => {
    const selectedItem = getState().pos.selectedItem
    if (!selectedItem || selectedItem?.discountType === discountType) {
      return
    } else {
      const discount = selectedItem.discount || 0
      const discountAmount =
        discountType === 'percent'
          ? selectedItem.quantity * selectedItem.price * (discount / 100)
          : discount
      const total = selectedItem.price * selectedItem.quantity - discountAmount

      const finalPrice =
        discountType === 'percent'
          ? selectedItem.price * (1 - discount / 100)
          : selectedItem.price > 0
          ? total / selectedItem?.quantity
          : 0

      updateState(state => {
        state.pos.selectedItem = {
          ...selectedItem,
          discountType,
          finalPrice,
          discountAmount,
          total,
        }
      })
    }
  },
  setSelectedItemPrice: (price: number) => {
    const selectedItem = getState().pos.selectedItem
    if (!selectedItem) {
      return
    } else {
      const discountType = selectedItem.discountType || 'percent'
      const discount = selectedItem.discount || 0

      const finalPrice =
        discountType === 'percent'
          ? price * (1 - discount / 100)
          : price - discount > 0
          ? (price * selectedItem.quantity - discount) / selectedItem.quantity
          : 0

      updateState(state => {
        state.pos.selectedItem = {
          ...selectedItem,
          discountType,
          finalPrice,
          price,
          discountAmount:
            selectedItem.discountType === 'percent'
              ? selectedItem.quantity * price * (discount / 100)
              : discount,
          total: finalPrice * selectedItem.quantity,
        }
      })
    }
  },
  updateSelectedItemDiscountValue: (discount: number) => {
    const newReceiptData = { ...getState().pos.receipt }
    const selectedItem = getState().pos.selectedItem
    if (!selectedItem) {
      return
    } else {
      const price =
        selectedItem.discountType === 'percent'
          ? roundNumberWithTwoDecimals(
              selectedItem.price * (1 - discount / 100)
            )
          : (selectedItem.quantity * selectedItem.price - discount) /
            selectedItem.quantity

      updateState(state => {
        state.pos.selectedItem = {
          ...selectedItem,
          discount: discount,
          finalPrice: price,
          discountAmount:
            selectedItem.discountType === 'percent'
              ? selectedItem.quantity * selectedItem.price * (discount / 100)
              : discount,
          total:
            price * (state.pos.selectedItem?.quantity || selectedItem.quantity),
        }
        state.pos.receipt = { ...calculateTotal(newReceiptData) }
      })
    }
  },
  confirmSelectedItem: () => {
    const selectedItem = getState().pos.selectedItem
    if (!selectedItem) {
      return
    } else {
      const receipt = getState().pos.receipt
      const itemToConfirmIndex = receipt.items.findIndex(
        e => e.id === selectedItem.id
      )
      updateState(state => {
        const tmpItems = state.pos.receipt.items
        tmpItems.splice(itemToConfirmIndex, 1, selectedItem)
        state.pos.receipt.items = tmpItems
        state.pos.receipt = { ...calculateTotal(state.pos.receipt) }
      })
    }
  },
  removeSelectedItem: () => {
    updateState(state => {
      state.pos.selectedItem = undefined
    })
  },
  resetReceipt: () => {
    updateState(state => {
      state.pos.receipt = createDraftReceipt('RECEIPT')
      state.pos.selectedItem = undefined
    })
  },
}

export const print = async (
  printerName: string | null | undefined,
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService',
  rawData: string | null | undefined,
  successMessage: string
) => {
  try {
    if (!printerName)
      return {
        success: false,
        text: 'Unesite ime printera',
      }

    if (printClient === 'neodynamicPrintService') {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        const printersList = (await JSPM.JSPrintManager.getPrinters().then(
          function (printersList) {
            return printersList
          }
        )) as any[]

        if (!printersList?.find(x => x === printerName))
          return {
            success: false,
            text: 'Naziv printera na uređaju ne odgovara trenutno spojenim printerima',
          }

        var cpj = new JSPM.ClientPrintJob()
        cpj.clientPrinter = new JSPM.InstalledPrinter(printerName || '')
        // cpj.clientPrinter = new JSPM.DefaultPrinter()
        cpj.binaryPrinterCommands = base64ToArrayBuffer(rawData)
        cpj.sendToClient()
        return {
          success: true,
          text: successMessage,
        }
      } else {
        return {
          success: false,
          text: 'Printer nije instaliran ili nije spojen!',
        }
      }
    } else {
      const printResponse = await fetch('http://localhost:7969/print', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          printerName,
          rawData,
        }),
      })
    }
  } catch (error) {}
}

export const printTestPos = async (
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService',
  deviceId: String
) => {
  const printJobResult = await apolloClient.query<PosTestPrintJobQuery>({
    query: PosTestPrintJobDocument,
    variables: {
      input: {
        posDeviceId: deviceId,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { rawData, printerName } = printJobResult.data.posTest_printJob

  return await print(
    printerName,
    printClient,
    rawData,
    'Račun je uspješno isprintan!'
  )
}

export const printPDF = async (
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService',
  linkPDF: string,
  printerName: string | null | undefined,
  namePDF: string,
  defaultPrinter: boolean,
  message: string
) => {
  try {
    if (!printerName && !defaultPrinter)
      return {
        success: false,
        text: 'Unesite ime printera',
      }

    if (printClient === 'neodynamicPrintService') {
      if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        if (!defaultPrinter) {
          const printersList = (await JSPM.JSPrintManager.getPrinters().then(
            function (printersList) {
              return printersList
            }
          )) as any[]

          if (!printersList?.find(x => x === printerName))
            return {
              success: false,
              text: 'Naziv printera na uređaju ne odgovara trenutno spojenim printerima',
            }
        }

        var cpj = new JSPM.ClientPrintJob()
        cpj.clientPrinter = defaultPrinter
          ? new JSPM.DefaultPrinter()
          : new JSPM.InstalledPrinter(printerName || '')

        var my_file1 = new JSPM.PrintFilePDF(
          linkPDF,
          JSPM.FileSourceType.URL,
          namePDF,
          1
        )
        cpj.files.push(my_file1)
        cpj.sendToClient()

        return {
          success: true,
          text: message,
        }
      } else {
        return {
          success: false,
          text: 'Printer nije instaliran ili nije spojen!',
        }
      }
    } else {
      const printResponse = await fetch('http://localhost:7969/print', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          printerName,
          rawData: 'dsfds',
        }),
      })
    }
  } catch (error) {}
}

export const printReceiptToPosPrinter = async (
  receiptId: String,
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService'
) => {
  const selectedDevice = getState().pos.selectedPosDevice
  assert(selectedDevice, 'No device selected')
  const printJobResult = await apolloClient.query<PosReceiptPrintJobQuery>({
    query: PosReceiptPrintJobDocument,
    variables: {
      input: {
        receiptId: receiptId,
        posDeviceId: selectedDevice?.id,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { rawData, printerName } = printJobResult.data.posReceipt_printJob

  if (
    printClient === 'neodynamicPrintService' &&
    JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed
  ) {
    JSPM.JSPrintManager.start()
      .then(async () => {
        return await print(
          printerName,
          printClient,
          rawData,
          'Račun je uspješno isprintan!'
        )
      })
      .catch(error => {
        console.log('JPM failed to start', error)
      })
  } else
    return await print(
      printerName,
      printClient,
      rawData,
      'Račun je uspješno isprintan!'
    )
}

export const printJournalToPosPrinter = async (
  journalId: String,
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService'
) => {
  const selectedDevice = getState().pos.selectedPosDevice
  assert(selectedDevice, 'No device selected')
  const printJobResult = await apolloClient.query<
    PosJournalPrintJobQuery,
    PosJournalPrintJobQueryVariables
  >({
    query: PosJournalPrintJobDocument,
    variables: {
      input: {
        posJournalId: journalId,
        posDeviceId: selectedDevice.id,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { rawData, printerName } = printJobResult.data.posJournal_printJob

  await print(
    printerName,
    printClient,
    rawData,
    'Blagajna je uspješno isprintana!'
  )
}

export const printInfoToPosPrinter = async (
  resourceBookingId: String,
  printClient: 'zoyyaPrintService' | 'neodynamicPrintService'
) => {
  const selectedDevice = getState().pos.selectedPosDevice

  assert(selectedDevice, 'No device selected')

  const printJobResult = await apolloClient.query<
    PosInfoPrintJobQuery,
    PosInfoPrintJobQueryVariables
  >({
    query: PosInfoPrintJobDocument,
    variables: {
      input: {
        id: Number(resourceBookingId),
        posDeviceId: selectedDevice.id,
      },
    },
    fetchPolicy: 'network-only',
  })

  const { rawData, printerName } = printJobResult.data.posInfo_printJob

  await print(
    printerName,
    printClient,
    rawData,
    'Info termina je uspješno isprintan!'
  )
}
