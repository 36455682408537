import React, { useCallback, useContext, useEffect } from 'react'
import {
  calculateDiscount,
  formatPriceNumberWithDelimiter,
  generateServiceSlug,
  getHoursInDay,
  roundNumberWithTwoDecimals,
  themeAccentColors,
} from 'src/helpers'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { useGetLocationLoading, useGetServiceTypes } from 'state/queries'
import { useProfessionAllQuery } from 'state/graphql'
import { useParams } from 'react-router'
import {
  Button,
  DeleteButton,
  FormField,
  FormContentContainer,
} from 'components'
import { FormFooterButtonsContainer } from 'components'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { getServiceHoursAndMinutes, serialize } from 'src/helpers'
import { MobileContext } from 'src/Context'
import { PlanUpgradeField } from 'components/PlanUpgradeField/PlanUpgradeField'
import { CheckMarkIcon } from 'components/Icon/Icons/Checkmark'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { EurExchangeRate } from '../../../../constants'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import { LoaderTW } from 'src/sdk/tw/Loader'
import { useLocation } from 'react-router-dom'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { ArrowRight } from 'src/components/Icon/Icons/ArrowRight'
import Hashids from 'hashids'
import { getCurrentLanguage } from 'src/localization'
import { trpc } from 'src/trpc'
import {
  ServiceDiscount,
  StyledDiscountInput,
} from 'src/views/CashRegister/NewReceipt/styles'
export const hasher = new Hashids('8bQ%q^h36iXZTWpzFoy')

type ServiceFormProps = {
  isNew?: boolean
  onDelete?: any
  hasOnlineBooking?: boolean
  setOpenBillingModal?: any
  form?: any
  isAppointment?: boolean
  onGoBack?: any
  setShowAddServiceForm?: (boolean) => any
}
export const ServiceForm = (props: ServiceFormProps) => {
  const {
    isNew,
    onDelete,
    hasOnlineBooking,
    setOpenBillingModal,
    form,
    isAppointment,
    onGoBack,
    setShowAddServiceForm,
  } = props
  const { t, navigateTo } = useSdk()
  const { pathname } = useLocation<any>()
  const { typeId, locationId, orgId } = useParams<any>()
  const { data: serviceTypes, loading: serviceLoading } =
    useGetServiceTypes(locationId)
  const loadOrg = useQuery(getOrganizationByIdGql, {
    variables: { id: orgId },
    fetchPolicy: 'cache-first',
  })
  const { data: organizationData } = loadOrg
  const org = organizationData?.organization?.get

  const { data: professionsData, loading: loadingProfessions } =
    useProfessionAllQuery()

  const professionsAll = professionsData?.profession?.all
  const professions = professionsAll?.filter(x => x?.kind === 'USER')
  const resourceTypes = professionsAll?.filter(x => x?.kind === 'RESOURCE')

  const {
    data: serviceTypeCategories,
    isLoading: serviceTypeCategoriesLoading,
  } = trpc.serviceTypeCategories_findMany.useQuery({
    locationId: BigInt(locationId),
    orgId,
  })
  const [inputValue, setInputValue] = React.useState<number | undefined>()

  useHeader({
    content: isAppointment
      ? null
      : t('translation.ServiceEditorForm.label-service'),
    onGoBack: navigateTo.servicesList,
  })
  const bookingAllowed = [
    {
      id: true,
      title: t('translation.ServiceEditorForm.label-online-booking-enabled'),
    },
    {
      id: false,
      title: t('translation.ServiceEditorForm.label-online-booking-disabled'),
    },
  ]

  const bookingConfirmation = [
    {
      id: true,
      title: t('translation.ServiceEditorForm.requiresConfirmation'),
    },
    {
      id: false,
      title: t('translation.ServiceEditorForm.doesntRequireConfirmation'),
    },
  ]

  const viewLocationData = useGetLocationLoading(locationId)
  const location = viewLocationData.data?.location?.get

  const durationOptions = getHoursInDay(location?.timeSlotDuration)

  const getServiceHOurs = useCallback(val => {
    return typeof val !== 'string' ? getServiceHoursAndMinutes(val) : val
  }, [])
  const isMobile = useContext(MobileContext)

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.id === props.data?.id
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel>
          {props.data.title || props.data?.name}
        </SelectOptionLabel>
      </SelectRow>
    )
  }
  const selectComponents = {
    MobileOption,
  }
  const updateAmounts = (
    price: number | string,
    discountValue: string,
    discountType: 'percent' | 'amount'
  ) => {
    const { totalAmount, discountAmount, discountRate } = calculateDiscount(
      price?.toString()?.replace(',', '.'),
      String(discountValue),
      discountType
    )

    form.setFieldValue('discountValue', discountValue)
    form.setFieldValue('discountAmount', discountAmount)
    form.setFieldValue('totalAmount', totalAmount)
    form.setFieldValue('discountRate', discountRate)
  }

  const updateDiscountType = (type: 'percent' | 'amount') => {
    form.setFieldValue('discountType', type)
    updateAmounts(form?.values?.price, form?.values?.discountValue, type)
  }

  const updateDiscount = e => {
    const value = e.target.value

    if (!value || /^[0-9,.]*$/.test(value)) {
      setInputValue(value)

      updateAmounts(form?.values?.price, value, form?.values?.discountType)
    }
  }

  useEffect(() => {
    setInputValue(form?.values?.discountValue)
  }, [])

  const onChangePrice = value => {
    if (value && !/^[0-9,.]*$/.test(value)) return

    const price = Number(value || 0)

    if (!price || price <= 0) {
      form.setFieldValue('discountValue', null)
      form.setFieldValue('discountAmount', 0)
      form.setFieldValue('totalAmount', 0)
      form.setFieldValue('discountRate', 0)
      setInputValue(0)
    } else {
      updateAmounts(
        price,
        form?.values?.discountValue,
        form?.values?.discountType
      )
    }
  }

  if (serviceLoading || loadingProfessions || serviceTypeCategoriesLoading)
    return <LoaderTW isComponent />

  const getDiscountSection = form => (
    <FieldRow>
      <div className="flex flex-col lg:mb-0 mb-4 w-full">
        <p className="font-medium my-[5px]">{'Cijena sa popustom'}</p>
        <div className="flex gap-3 items-center">
          <div className="flex flex-col items-end">
            <p>{`${formatPriceNumberWithDelimiter(
              form?.values?.totalAmount
            )} ${org?.currency?.id}`}</p>

            {Number(form?.values?.discountAmount || 0) > 0 ? (
              <p
                className={`${'text-sm text-zoyya-secondaryDark line-through'}`}
              >{`${formatPriceNumberWithDelimiter(
                form?.values?.price || 0
              )} ${org?.currency?.id}`}</p>
            ) : null}
          </div>
          <ServiceDiscount style={{ position: 'unset' }}>
            <div
              style={{
                width: 65,
                marginRight: 5,
              }}
            >
              <StyledDiscountInput
                name={`discountValue`}
                type="text"
                value={inputValue}
                onChange={e => updateDiscount(e)}
                placeholder="popust"
                autoComplete="off"
              />
            </div>
            <Button
              type="button"
              buttonType={
                form?.values?.discountType === 'percent'
                  ? 'primary'
                  : 'secondary'
              }
              label={'%'}
              onClick={() => updateDiscountType('percent')}
              size="small"
              style={{
                fontSize: 16,
                fontWeight: 300,
              }}
            />
            <Button
              type="button"
              buttonType={
                form?.values?.discountType === 'amount'
                  ? 'primary'
                  : 'secondary'
              }
              label={org?.currency?.id}
              onClick={() => updateDiscountType('amount')}
              size="small"
              style={{ width: 'auto' }}
            />
          </ServiceDiscount>
        </div>
      </div>
    </FieldRow>
  )

  return (
    <FormContentContainer style={{ paddingBottom: 0 }}>
      {isAppointment ? (
        <div
          className="flex items-center bg-zoyya-lightBackground h-[60px] p-6 -mt-3 lg:-m-6"
          style={{ marginBottom: 0 }}
        >
          <ArrowLeft onClick={onGoBack} className="mr-3 !hidden lg:!block" />
          <div className="text-[22px] cursor-pointer flex items-center">
            {t('translation.AppointmentModal.title-newService')}
          </div>
        </div>
      ) : null}
      {isAppointment ? (
        <div className="overflow-auto px-4 lg:px-1 pt-4 no-scrollbar">
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-name')}
                name="name"
                autoFocus
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-service-type')}
                name="type"
                options={serviceTypes}
                parseValue={val => {
                  return serviceTypes?.find(e => e.id === val?.id)
                }}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                name="serviceTypeCategoryId"
                label={t(
                  'translation.ServiceEditorForm.label-serviceTypeCategory'
                )}
                options={serviceTypeCategories!}
                styles={{
                  groupHeading: (provided, state) => {
                    return { ...provided, fontSize: '15px' }
                  },
                }}
                parseValue={val => {
                  const parent = serviceTypeCategories?.find(
                    e => !!e.options?.find(x => x.id?.toString() === val)
                  )
                  const value = parent?.options?.find(
                    x => x.id?.toString() === val
                  )
                  return val ? value : null
                }}
                formatValue={val => val?.id?.toString()}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="profession"
                label={t('translation.ServiceEditorForm.label-profession')}
                options={professions!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            {hasOnlineBooking ? (
              <>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-booking-settings'
                    )}
                    name="bookingAllowed"
                    parseValue={val => bookingAllowed.find(e => e.id === val)}
                    formatValue={e => e?.id}
                    options={bookingAllowed}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-online-booking'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>

                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.bookingConfirmation'
                    )}
                    name="hasBookingConfirmation"
                    parseValue={val =>
                      bookingConfirmation.find(e => e.id === val)
                    }
                    formatValue={e => e?.id}
                    options={bookingConfirmation}
                    helpText={t(
                      'translation.ServiceEditorForm.bookingConfirmationHint'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>
              </>
            ) : null}
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-gross-duration')}
                name="durationMinutes"
                parseValue={val =>
                  durationOptions.find(e => e?.id === getServiceHOurs(val))
                }
                formatValue={e => e?.id}
                options={durationOptions}
                components={selectComponents}
                helpText={t(
                  'translation.ServiceEditorForm.label-gross-duration-helpText'
                )}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-net-duration')}
                name="netDurationMinutes"
                helpText={t(
                  'translation.ServiceEditorForm.label-net-duration-helpText'
                )}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-price')}
                name="price"
                shouldDisplayZeroAsValue
                parseValue={value => {
                  return !value
                    ? parseFloat('0')
                    : parseFloat(value?.replace(',', '.'))
                }}
                onChange={onChangePrice}
              />
            </FieldColumn>
            {isMobile ? getDiscountSection(form) : null}
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.currency')}
                name="currencyId"
                disabled
                helpText={t('translation.ServiceEditorForm.tooltip-currency')}
              />
            </FieldColumn>
            {org?.vatStatus === 'Y' ? (
              <FieldColumn>
                <FormField.Text
                  label={t('translation.ServiceEditorForm.label-vat')}
                  name="vatRate"
                  shouldDisplayZeroAsValue
                  parseValue={value => {
                    return !value ? parseFloat('0') : parseFloat(value)
                  }}
                />
              </FieldColumn>
            ) : null}
          </FieldRow>
          {!isMobile ? getDiscountSection(form) : null}
          <FieldRow>
            <FieldColumn>
              <FormField.ColorPicker
                label={t('translation.ServiceEditorForm.color')}
                name="color"
                options={themeAccentColors}
                helpText={t(
                  'translation.ServiceEditorForm.tooltips-info-service-color'
                )}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="resourceType"
                label={t('translation.ServiceEditorForm.vrsta-resursa')}
                options={resourceTypes!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
                components={selectComponents}
                parseValue={val => resourceTypes?.find(e => e?.id === val?.id)}
              />
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            <FieldColumn>
              <FormField.RichTextEditor
                val={
                  !form.values.description
                    ? '<p></p>'
                    : Array.isArray(form.values.description)
                    ? serialize(form.values.description)
                    : form.values.description
                }
                label={t('translation.ServiceEditorForm.label-description')}
                name="description"
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-description'
                )}
                showGenerateButton
                generateButtonProps={{
                  orgId,
                  locationId: BigInt(locationId),
                  fieldName: 'description',
                  entity: 'serviceDescription',
                  name: form.values.name,
                  price: form.values.price,
                  durationMinutes: form.values.durationMinutes,
                  hasBookingConfirmation: form.values.hasBookingConfirmation,
                  serviceTypeId: form.values.type?.id,
                  disabled: !form.values.name,
                }}
              />
            </FieldColumn>
          </FieldRow>
        </div>
      ) : (
        <div>
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-name')}
                name="name"
                autoFocus
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-service-type')}
                name="type"
                options={serviceTypes}
                parseValue={val => {
                  return serviceTypes?.find(e => e.id === val?.id)
                }}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="serviceTypeCategoryId"
                label={t(
                  'translation.ServiceEditorForm.label-serviceTypeCategory'
                )}
                options={serviceTypeCategories!}
                styles={{
                  groupHeading: (provided, state) => {
                    return { ...provided, fontSize: '15px' }
                  },
                }}
                parseValue={val => {
                  const parent = serviceTypeCategories?.find(
                    e => !!e.options?.find(x => x.id?.toString() === val)
                  )
                  const value = parent?.options?.find(
                    x => x.id?.toString() === val
                  )
                  return val ? value : null
                }}
                formatValue={val => val?.id?.toString()}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="profession"
                label={t('translation.ServiceEditorForm.label-profession')}
                options={professions!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            {hasOnlineBooking ? (
              <>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-booking-settings'
                    )}
                    name="bookingAllowed"
                    parseValue={val => bookingAllowed.find(e => e.id === val)}
                    formatValue={e => e?.id}
                    options={bookingAllowed}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-online-booking'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>

                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.bookingConfirmation'
                    )}
                    name="hasBookingConfirmation"
                    parseValue={val =>
                      bookingConfirmation.find(e => e.id === val)
                    }
                    formatValue={e => e?.id}
                    options={bookingConfirmation}
                    helpText={t(
                      'translation.ServiceEditorForm.bookingConfirmationHint'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>
              </>
            ) : (
              <PlanUpgradeField
                title={t(
                  'translation.LocationBooking.label-online-booking-select'
                )}
                text={t('translation.App.online-booking-disabled')}
                handleClick={() => setOpenBillingModal(true)}
              />
            )}
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-gross-duration'
                    )}
                    name="durationMinutes"
                    parseValue={val =>
                      durationOptions.find(e => e?.id === getServiceHOurs(val))
                    }
                    formatValue={e => e?.id}
                    options={durationOptions}
                    components={selectComponents}
                    helpText={t(
                      'translation.ServiceEditorForm.label-gross-duration-helpText'
                    )}
                  />
                </FieldColumn>
                <FieldColumn>
                  <FormField.Text
                    label={t(
                      'translation.ServiceEditorForm.label-net-duration'
                    )}
                    name="netDurationMinutes"
                    helpText={t(
                      'translation.ServiceEditorForm.label-net-duration-helpText'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                <FieldColumn>
                  <FormField.Text
                    label={t('translation.ServiceEditorForm.label-price')}
                    name="price"
                    shouldDisplayZeroAsValue
                    parseValue={value => {
                      return !value
                        ? parseFloat('0')
                        : parseFloat(value?.replace(',', '.'))
                    }}
                    onChange={onChangePrice}
                  />
                </FieldColumn>
                {isMobile ? getDiscountSection(form) : null}
                <FieldColumn>
                  <FormField.Text
                    label={t('translation.ServiceEditorForm.currency')}
                    name="currencyId"
                    disabled
                    helpText={t(
                      'translation.ServiceEditorForm.tooltip-currency'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                {org?.vatStatus === 'Y' ? (
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.ServiceEditorForm.label-vat')}
                      name="vatRate"
                      shouldDisplayZeroAsValue
                      parseValue={value => {
                        return !value ? parseFloat('0') : parseFloat(value)
                      }}
                    />
                  </FieldColumn>
                ) : null}
                <FieldColumn>
                  <FormField.ColorPicker
                    label={t('translation.ServiceEditorForm.color')}
                    name="color"
                    options={themeAccentColors}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-color'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="resourceType"
                label={t('translation.ServiceEditorForm.vrsta-resursa')}
                options={resourceTypes!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
                components={selectComponents}
                parseValue={val => resourceTypes?.find(e => e?.id === val?.id)}
              />
            </FieldColumn>
          </FieldRow>
          {!isMobile ? getDiscountSection(form) : null}
          <FieldRow>
            <FieldColumn>
              <FormField.RichTextEditor
                val={
                  !form.values.description
                    ? '<p></p>'
                    : Array.isArray(form.values.description)
                    ? serialize(form.values.description)
                    : form.values.description
                }
                label={t('translation.ServiceEditorForm.label-description')}
                showGenerateButton
                generateButtonProps={{
                  orgId,
                  locationId: BigInt(locationId),
                  fieldName: 'description',
                  entity: 'serviceDescription',
                  name: form.values.name,
                  price: form.values.price,
                  durationMinutes: form.values.durationMinutes,
                  hasBookingConfirmation: form.values.hasBookingConfirmation,
                  serviceTypeId: form.values.type?.id,
                  disabled: !form.values.name,
                }}
                name="description"
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-description'
                )}
              />
            </FieldColumn>
          </FieldRow>
        </div>
      )}
      {!isNew && !isAppointment ? (
        <div className="flex justify-start lg:mb-0 mb-6">
          <ButtonTW
            label="Posjeti stranicu usluge"
            variant="link"
            size="small"
            iconRight
            icon={<ArrowRight size={'smaller'} />}
            onClick={e => {
              e.preventDefault()
              window.open(
                `${
                  import.meta.env.VITE_CLIENT_URL
                }/${getCurrentLanguage()}/${location?.slug}/services/${generateServiceSlug(
                  form.values?.type?.name
                )}/${hasher.encode(
                  BigInt(form.values?.id)
                )}/${generateServiceSlug(form.values?.name)}`,
                '_blank',
                'noreferrer'
              )
            }}
          />
        </div>
      ) : null}

      {!isNew ? (
        <DeleteButton
          withConfirm
          onConfirm={onDelete}
          confirmationMessage={t(
            'translation.ServiceEditorForm.title-service-confirm-delete'
          )}
        />
      ) : null}
      {!isMobile && !isAppointment ? (
        <FormFooterButtonsContainer>
          <Button
            footerButton
            buttonType={'text'}
            name="close"
            type={'button'}
            label={t('translation.ServiceEditorForm.button-close')}
            onClick={() => {
              pathname.includes(`/giftCardPackageTemplates`)
                ? setShowAddServiceForm?.(false)
                : navigateTo.servicesList({
                    locationId,
                    typeId,
                  })
            }}
          />
          <Button
            type={'submit'}
            buttonType={'primary'}
            label={t('translation.ServiceEditorForm.button-save')}
            name="saveChanges"
          />
        </FormFooterButtonsContainer>
      ) : null}
    </FormContentContainer>
  )
}
