import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useInviteUser } from 'state/queries'
import { formatPhone, getRatingStars } from 'src/helpers'
import { useAppServices } from 'sdk/appServices'
import { useSdk } from 'sdk'
import { useParams } from 'react-router'
import { useHeader } from 'mobile/Header'
import styled from 'styled-components'
import {
  useClientGetBookingsQuery,
  AppointmentStatusEnum,
  AppointmentCancellationEnum,
  useClientGetQuery,
} from 'state/graphql'
import { orderBy } from 'lodash'
import { sumBy } from 'lodash'
import { Button, Loader } from 'components'
import { EmptyList } from 'components/EmptyList/EmptyList'
import { icons } from 'components/Icon'
import AppointmentCardMobile from 'views/Clients/AppointmentCardMobile'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { useACL } from 'src/sdk/acl'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import {
  ActionButtonContainer,
  ActionButtonIconContainer,
  ActionButtonLabel,
  IconWrapper,
  MoreOption,
  MoreOptions,
  MoreOptionsOverlay,
  MoreOptionTitle,
} from './ClientDetailsGroupMobile'

type ClientDetailsMobileProps = {
  onEdit?: () => void
  values?: any
  isAppointment?: boolean
  onReminders?: () => void
  onGiftCards?: () => void
  onProducts?: () => void
}

const ClientDetailsMobile = (props: ClientDetailsMobileProps) => {
  const { id, orgId } = useParams<any>()
  const {
    onEdit,
    values,
    isAppointment,
    onReminders,
    onGiftCards,
    onProducts,
  } = props
  const { t, navigateTo, useQuery } = useSdk()
  const loadOrg = useQuery(getOrganizationByIdGql, { variables: { id: orgId } })
  const { data: organizationData } = loadOrg
  const currency = organizationData?.organization?.get?.currency?.currencyCode

  const { data: clientData, loading: loadingClient } = useClientGetQuery({
    variables: {
      id,
    },
    skip: values?.id ? !!values?.id : !id,
  })

  const [isMoreOpen, setIsMoreOpen] = useState(false)

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsMoreOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const tabs = [
    { key: 'future', label: t('translation.ClientDetailsMobile.future-dates') },
    { key: 'past', label: t('translation.ClientDetailsMobile.past-dates') },
    {
      key: 'canceled',
      label: t('translation.ClientDetailsMobile.canceled-dates'),
    },
    { key: 'missed', label: t('translation.ClientDetailsMobile.missed-dates') },
  ]

  const { hasPermission } = useACL()
  const client = values || clientData?.client?.get
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const fixedContainer = useRef<any>(null)

  useHeader({
    content: !isAppointment
      ? t('translation.ClientDetailsMobile.header-title')
      : null,
    onGoBack: navigateTo.clientsList,
  })

  const clientBookingsData = useClientGetBookingsQuery({
    variables: { clientId: client?.id },
    skip: !client?.id,
  })

  const [filter, setFilter] = useState('future')
  const [isInitialFilter, setIsInitialFilter] = useState(true)

  const allData = clientBookingsData?.data?.client?.getBookings

  const sortedAllEvents = orderBy(
    allData || [],
    ['date', 'startTime'],
    ['desc', 'asc']
  )

  const sortedFutureEvents = orderBy(
    allData || [],
    ['date', 'startTime'],
    ['asc']
  )?.filter(
    event =>
      moment(event?.services?.[0]?.endTimeUtc).isAfter(moment()) &&
      event?.status !== AppointmentStatusEnum.Canceled
  )

  const sortedPastEvents = sortedAllEvents?.filter(
    event =>
      moment(event?.services?.[0]?.endTimeUtc).isBefore(moment()) &&
      event?.status !== AppointmentStatusEnum.Canceled
  )

  useEffect(() => {
    if (isInitialFilter) {
      setFilter(sortedFutureEvents?.length > 0 ? 'future' : 'past')
    }
  }, [sortedFutureEvents, isInitialFilter])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (clientBookingsData?.loading || loadingClient)
    return <Loader isComponent={false} />

  const canceledAppointments = sortedAllEvents?.filter(
    event =>
      event?.status === AppointmentStatusEnum.Canceled &&
      event?.reason !== AppointmentCancellationEnum.ClientNoShow
  )

  const missedAppointments = sortedAllEvents
    ?.filter(event => event?.status === AppointmentStatusEnum.Canceled)
    ?.filter(
      event => event?.reason === AppointmentCancellationEnum.ClientNoShow
    )

  const reviewsTotalSum = sumBy(sortedAllEvents, 'reviewRating')
  const reviewsTotalCount = sortedAllEvents.filter(item => item?.reviewRating)
    ?.length

  const reviewNumber =
    reviewsTotalSum && sortedAllEvents[0]
      ? Math.round(reviewsTotalSum / reviewsTotalCount)
      : 0
  const totalBookingsPrice =
    allData
      ?.filter(
        item =>
          item?.status !== AppointmentStatusEnum.Canceled &&
          item?.status !== 'noShow'
      )
      ?.map(
        item =>
          item?.services?.reduce(
            (n, service) =>
              n + (service?.service?.price ? service?.service?.price : 0),
            0
          )
      )
      ?.reduce((a, b) => {
        //@ts-ignore
        return a + b
      }, 0) || 0

  return (
    <>
      <div className="overflow-auto">
        <div className="fixed bg-white z-[1] w-full" ref={fixedContainer}>
          <div className="bg-[#f5f5f5] py-4">
            <div className="flex items-center mb-4 relative px-4">
              <div
                className="flex relative shrink-0"
                onClick={() => {
                  if (onEdit) {
                    onEdit()
                  } else {
                    navigateTo.editClient({ id: id })
                  }
                }}
              >
                {client?.defaultAvatarUrl &&
                !client?.defaultAvatarUrl?.endsWith('null') ? (
                  <img
                    className="w-[68px] h-[68px] border border-white rounded-full object-cover shrink-0"
                    src={client?.defaultAvatarUrl}
                  />
                ) : (
                  <div className="w-[68px] h-[68px] border border-white rounded-full font-medium text-[22px] text-center flex justify-center items-center text-zoyya-primary bg-white shrink-0">
                    {client?.firstName
                      ? client?.firstName.charAt(0).toUpperCase() +
                        client?.lastName.charAt(0).toUpperCase()
                      : 'NN'}
                  </div>
                )}
                {client?.userId ? (
                  <div className="absolute right-0 bottom-0 width-fit ">
                    <img
                      src={'/zoyyaLetter.png'}
                      className="h-6 w-6 rounded-full"
                    />
                  </div>
                ) : null}
              </div>
              <div className="ml-4 w-full">
                <div className="flex justify-between items-center">
                  <p className="text-[20px] font-semibold flex">
                    {`${client?.firstName} ${client?.lastName}`}
                  </p>
                  {isAppointment &&
                  !hasPermission('EDIT_APPOINTMENT') ? null : (
                    <ButtonTW
                      variant={'secondary'}
                      size="small"
                      label={t('translation.Client.izmjeni')}
                      icon={<icons.Edit size={'size16'} />}
                      onClick={() => {
                        if (onEdit) {
                          onEdit()
                        } else {
                          navigateTo.editClient({ id: id })
                        }
                      }}
                      className={'h-[29px]'}
                    />
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    className="text-[10px] pl-px -mt-3"
                    style={{ marginTop: '5px' }}
                  >
                    {getRatingStars(reviewNumber)}
                  </div>

                  {client?.status === 'BLOCKED' ? (
                    <div className="bg-zoyya-accent5 pt-1 px-3 text-sm rounded-sm uppercase text-white w-fit ml-3">
                      {t('translation.ClientsList.label-blocked')}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="-mx-5">
              <ActionButtons<any>
                width={windowWidth}
                // scrollLength={
                //   ((!isAppointment
                //     ? !client?.user?.id && client?.email
                //       ? 1.5
                //       : 0.5
                //     : !client?.user?.id && client?.email
                //     ? 0.5
                //     : 0) *
                //     windowWidth) /
                //   5.5
                // }
              >
                {/* {isAppointment && !hasPermission('EDIT_APPOINTMENT') ? null : (
                  <a
                    className="flex flex-col justify-center items-center"
                    onClick={() => {
                      if (onEdit) {
                        onEdit()
                      } else {
                        navigateTo.editClient({ id: id })
                      }
                    }}
                  >
                    <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                      <icons.Edit size={'smaller'} />
                    </div>
                    <p className="text-[10px] text-[#555] text-center mt-1 font-medium">
                      {t('translation.ClientDetailsMobile.edit-label')}
                    </p>
                  </a>
                )} */}
                <ActionButtonContainer onClick={() => setIsMoreOpen(true)}>
                  <ActionButtonIconContainer>
                    <icons.PhoneBold
                      size={'small'}
                      style={{
                        color: '#333',
                        fill: '#333',
                        strokeWidth: 1,
                      }}
                    />
                  </ActionButtonIconContainer>
                  <ActionButtonLabel>
                    {t('translation.MessagesList.label-contact')}
                  </ActionButtonLabel>
                </ActionButtonContainer>
                <a
                  className={`flex flex-col justify-center items-center`}
                  onClick={() =>
                    onGiftCards
                      ? onGiftCards()
                      : navigateTo.clientGiftCards({
                          id: values?.id ? values?.id : id,
                        })
                  }
                >
                  <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                    <icons.Gift size={'small'} />
                  </div>
                  <p className=" text-[10px] text-[#555] text-center mt-1 font-medium">
                    {'Program vjernosti'}
                  </p>
                </a>

                <a
                  onClick={() =>
                    // if its in client list reminders, go there, otherwise (from appointment) run handler
                    onReminders
                      ? onReminders()
                      : navigateTo.clientReminders({
                          id: values?.id ? values?.id : id,
                        })
                  }
                  className={`flex flex-col justify-center items-center`}
                >
                  <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                    <icons.Bell size={'small'} />
                  </div>
                  <p className="text-[10px] text-[#555] text-center mt-1 font-medium">
                    {t('translation.ClientReminders.reminders-label')}
                  </p>
                </a>
                {!isAppointment ? (
                  <a
                    className="flex flex-col justify-center items-center"
                    onClick={() => navigateTo.clientAttachments({ id: id })}
                  >
                    <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                      <icons.Download size={'smaller'} />
                    </div>
                    <p className="text-[10px] text-[#555] text-center mt-1 font-medium">
                      {t('translation.Appointment.privitci')}
                    </p>
                  </a>
                ) : null}

                <a
                  className={`flex flex-col justify-center items-center`}
                  onClick={() =>
                    onProducts
                      ? onProducts()
                      : navigateTo.clientProducts({
                          id: values?.id ? values?.id : id,
                        })
                  }
                >
                  <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                    <icons.Warehouse size={'small'} />
                  </div>
                  <p className=" text-[10px] text-[#555] text-center mt-1 font-medium">
                    {t('translation.ClientProductsList.label-products')}
                  </p>
                </a>

                {/* {!client?.user?.id && client?.email ? (
                  <a
                    className="flex flex-col justify-center items-center"
                    onClick={() => {
                      if (client) {
                        showPopup({
                          onConfirm: () => sendInvitation(client),
                          titleText: t(
                            'translation.ClientsList.title-confirmSend',
                            {
                              firstName: client?.firstName,
                              lastName: client?.lastName,
                            }
                          ),
                          contentText: t(
                            'translation.ClientsList.label-sendInviteInfo'
                          ),
                        })
                      }
                    }}
                  >
                    <div className="w-12 h-12 rounded-full bg-white shadow-[0_0_10px_rgba(0,0,0,0.05)] flex justify-center items-center">
                      <icons.SendEmail size={'smaller'} />
                    </div>
                    <p className="text-[10px] text-[#555] text-center mt-1 font-medium">
                      {t('translation.ClientDetailsMobile.invitation')}
                    </p>
                  </a>
                ) : null} */}
              </ActionButtons>
            </div>
          </div>
          {isMoreOpen ? (
            <MoreMenu client={client} wrapperRef={wrapperRef} />
          ) : null}
          <div className="m-4 flex items-center">
            <div className="flex flex-col shrink-0 border-r-1 border-zoyya-outline mr-3 pr-3">
              <span className="max-xs:text-[18px] text-[22px] font-medium">
                {Math.round(totalBookingsPrice)?.toLocaleString('hr-HR') +
                  ' ' +
                  (currency ? currency : '')}
              </span>
              <span className="text-sm text-[#555]">
                {t('translation.ClientDetailsMobile.total-sales')}
              </span>
            </div>
            <div className="flex gap-3">
              <div className="flex flex-col">
                <span className="text-[16px] font-medium">
                  {sortedFutureEvents?.length + sortedPastEvents?.length}
                </span>
                <span className="text-sm text-[#555]">
                  {t('translation.ClientDetailsMobile.total-bookings')}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-[16px] font-medium">{`${missedAppointments?.length} (${canceledAppointments?.length})`}</span>
                <span className="text-sm text-[#555]">
                  {`${t('translation.ClientDetails.noShows')} ${t(
                    'translation.ClientDetailsMobile.canceled'
                  )}`}
                </span>
              </div>
            </div>
          </div>

          <Tabs
            tabs={tabs}
            selectedTab={filter}
            onSelectTab={setFilter}
            setIsInitialFilter={setIsInitialFilter}
          />
        </div>
        <div
          className="my-6 mx-4"
          style={{
            paddingTop: fixedContainer?.current?.clientHeight - 20 || '275px',
          }}
        >
          <div>
            {(filter === 'future' && !sortedFutureEvents.length) ||
            (filter === 'past' && !sortedPastEvents.length) ||
            (filter === 'canceled' && !canceledAppointments.length) ||
            (filter === 'missed' && !missedAppointments.length) ? (
              <EmptyList
                title={
                  filter === 'future'
                    ? t(
                        'translation.RezervationsDesktop.title-no-future-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                    : filter === 'past'
                    ? t(
                        'translation.RezervationsDesktop.title-no-past-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                    : filter === 'canceled'
                    ? t(
                        'translation.RezervationsDesktop.title-no-canceled-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                    : t(
                        'translation.RezervationsDesktop.title-no-missed-appointments',
                        {
                          clientName:
                            client?.firstName + ' ' + client?.lastName,
                        }
                      )
                }
              />
            ) : (
              <>
                {filter === 'future'
                  ? sortedFutureEvents?.map((event, idx) => (
                      <AppointmentCardMobile
                        key={idx}
                        id={event?.id}
                        notes={event?.notes}
                        status={event?.status}
                        date={event?.date}
                        startTime={event?.startTime}
                        endTime={event?.endTime}
                        reason={event?.reason}
                        reviewRating={event?.reviewRating}
                        reviewComment={event?.reviewComment}
                        isOnlineBooking={event?.isOnlineBooking}
                        services={event?.services}
                      />
                    ))
                  : filter === 'past'
                  ? sortedPastEvents.map((event, idx) => (
                      <AppointmentCardMobile
                        key={idx}
                        id={event?.id}
                        notes={event?.notes}
                        status={event?.status}
                        date={event?.date}
                        startTime={event?.startTime}
                        endTime={event?.endTime}
                        reason={event?.reason}
                        reviewRating={event?.reviewRating}
                        reviewComment={event?.reviewComment}
                        isOnlineBooking={event?.isOnlineBooking}
                        services={event?.services}
                      />
                    ))
                  : filter === 'canceled'
                  ? canceledAppointments.map((event, idx) => (
                      <AppointmentCardMobile
                        key={idx}
                        id={event?.id}
                        notes={event?.notes}
                        status={event?.status}
                        date={event?.date}
                        startTime={event?.startTime}
                        endTime={event?.endTime}
                        reason={event?.reason}
                        reviewRating={event?.reviewRating}
                        reviewComment={event?.reviewComment}
                        isOnlineBooking={event?.isOnlineBooking}
                        services={event?.services}
                      />
                    ))
                  : filter === 'missed'
                  ? missedAppointments.map((event, idx) => (
                      <AppointmentCardMobile
                        key={idx}
                        id={event?.id}
                        notes={event?.notes}
                        status={event?.status}
                        date={event?.date}
                        startTime={event?.startTime}
                        endTime={event?.endTime}
                        reason={event?.reason}
                        reviewRating={event?.reviewRating}
                        reviewComment={event?.reviewComment}
                        isOnlineBooking={event?.isOnlineBooking}
                        services={event?.services}
                      />
                    ))
                  : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientDetailsMobile

export const MoreMenu = ({ client, wrapperRef, isDesktop = false }) => {
  const { t } = useSdk()
  const [inviteUser] = useInviteUser()
  const appServices = useAppServices()
  const { showPopup } = usePopup()

  async function sendInvitation(values) {
    await inviteUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender,
          mobilePhone: formatPhone(values.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }
  return (
    <MoreOptionsOverlay className={`${isDesktop ? '!h-fit !w-max' : ''}`}>
      <MoreOptions
        ref={wrapperRef}
        className={`!top-[147px] ${
          isDesktop
            ? 'w-max left-0  cursor-pointer'
            : 'w-[calc(100%-85px)] right-5'
        }`}
      >
        <a
          className={`flex hover:bg-zoyya-lightBackground rounded-lg transition-all items-center p-2.5 ${
            client?.mobilePhone
              ? 'opacity-1 '
              : 'opacity-50 pointer-events-none'
          }`}
          href={'sms:' + client?.mobilePhone}
          target="_blank"
        >
          <IconWrapper>
            <icons.Sms size={'smaller'} />
          </IconWrapper>
          <MoreOptionTitle>
            {t('translation.ClientDetailsMobile.sms-label')}
          </MoreOptionTitle>
        </a>

        <a
          className={`flex  hover:bg-zoyya-lightBackground transition-all rounded-lg items-center p-2.5 ${
            client?.mobilePhone
              ? 'opacity-1 '
              : 'opacity-50 pointer-events-none'
          }`}
          href={'tel:' + client?.mobilePhone}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper>
            <icons.PhoneBold size={'smaller'} />
          </IconWrapper>
          <MoreOptionTitle>
            {t('translation.ClientDetailsMobile.call-label')}
          </MoreOptionTitle>
        </a>
        <a
          className={`flex hover:bg-zoyya-lightBackground  transition-all rounded-lg items-center p-2.5 ${
            client?.mobilePhone
              ? 'opacity-1 '
              : 'opacity-50 pointer-events-none'
          }`}
          href={`https://wa.me/${client?.mobilePhone}`}
          target="_blank"
        >
          <IconWrapper>
            <icons.WhatsApp size={'smaller'} />
          </IconWrapper>
          <MoreOptionTitle>WhatsApp</MoreOptionTitle>
        </a>
        <a
          className={`flex hover:bg-zoyya-lightBackground transition-all rounded-lg items-center p-2.5 ${
            client?.email ? 'opacity-1 ' : 'opacity-50 pointer-events-none'
          }`}
          href={'mailto:' + client?.email}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper>
            <icons.Email size={'smaller'} />
          </IconWrapper>
          <MoreOptionTitle>Email</MoreOptionTitle>
        </a>

        {/* to be completed for attachments if needed */}
        {/* {!isNew && !isGroupLocation ? (
        <MoreOption
          onClick={() => setActiveForm('clientAttachments')}
        >
          <IconWrapper>
            <icons.Download size={'smaller'} />
          </IconWrapper>
          <MoreOptionTitle>
            {t('translation.Appointment.privitci')}
          </MoreOptionTitle>
        </MoreOption>
      ) : null} */}
        {!(client?.user?.id || client?.userId) && client?.email ? (
          <a
            className={`flex hover:bg-zoyya-lightBackground  transition-all rounded-lg items-center p-2.5 `}
            onClick={() => {
              if (client) {
                showPopup({
                  onConfirm: () => sendInvitation(client),
                  titleText: t('translation.ClientsList.title-confirmSend', {
                    firstName: client?.firstName,
                    lastName: client?.lastName,
                  }),
                  contentText: t(
                    'translation.ClientsList.label-sendInviteInfo'
                  ),
                })
              }
            }}
          >
            <IconWrapper>
              <icons.SendEmail size={'smaller'} stroke={1} />
            </IconWrapper>
            <MoreOptionTitle>
              {t('translation.ClientDetailsMobile.sendInvite-label')}
            </MoreOptionTitle>
          </a>
        ) : null}
      </MoreOptions>
    </MoreOptionsOverlay>
  )
}

export const ActionButtons = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  a {
    min-width: ${props => props.width / 5.5}px;
    position: relative;
    animation: linear;
    animation-name: run;
    animation-duration: 1.5s;
    animation-delay: 1.5s;

    @keyframes run {
      0% {
        right: 0;
      }
      50% {
        right: ${props => props.scrollLength}px;
      }
      100% {
        right: 0;
      }
    }
  }
`

export const MoreOptionLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`

const Tabs = ({ tabs, selectedTab, onSelectTab, setIsInitialFilter }) => {
  return (
    <div className="flex gap-3 lg:gap-10 lg:pl-6 w-full bg-zoyya-grayLighter border-zoyya-text/10 border-1">
      {tabs.map(tab => (
        <div
          key={tab.key}
          onClick={() => {
            setIsInitialFilter(false)
            onSelectTab(tab.key)
          }}
          className={`flex-1 flex items-center justify-center lg:px-3 lg:flex-[unset] text-center py-3 cursor-pointer ${
            selectedTab === tab.key
              ? 'border-b-[3px] border-zoyya-primary'
              : 'border-b-[3px] border-transparent'
          }`}
        >
          <p>{tab.label}</p>
        </div>
      ))}
    </div>
  )
}
