import React from 'react'
import {
  useGetOrganizationByIdQuery,
  useWmsOrderCreateMutation,
  useWmsOrderItemCreateMutation,
  useWmsWarehouseAllQuery,
  WmsGetActiveWarehouseDocument,
  WmsOrderListDocument,
} from 'src/state/graphql'
import { GoodsReceivedForm } from './GoodsReceivedForm'
import { useSdk } from 'sdk'
import { Loader } from 'src/components'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { TypeWarehouse } from './EditGoodsReceived'
import { getVatAmounts, roundNumberWithTwoDecimals } from 'src/helpers'

const NewGoodsRemoved = () => {
  const { t, appServices, navigateTo, useParams } = useSdk()
  const params = useParams<{ orgId: string }>()
  const { pathname } = useLocation()
  const [createOrder, { loading: createLoading }] = useWmsOrderCreateMutation()

  const { data: allWarehouseData, loading: allWarehouseLoading } =
    useWmsWarehouseAllQuery()
  const allWarehouses =
    (allWarehouseData?.wmsWarehouse_all as TypeWarehouse[]) || []

  const { data: organizationData, loading: orgLoading } =
    useGetOrganizationByIdQuery({
      variables: { id: params.orgId },
    })

  const org = organizationData?.organization?.get
  const hasVat = org?.vatStatus === 'Y'

  const [createItemMutation, { loading: loadingCreateItem }] =
    useWmsOrderItemCreateMutation()

  const handleSubmit = async (values, productFormRef, saveProduct) => {
    const input = {
      supplierId: values?.supplier?.id,
      warehouseId: values?.warehouse?.id,
      orderDate: moment(values?.orderDate).format('YYYY-MM-DD'),
      refNo: values?.refNo,
      invoiceNo: values?.invoiceNo,
      warehouseType: 'RETAIL',
      handlingCosts: values?.handlingCosts
        ? parseFloat(values?.handlingCosts?.toString().replace(',', '.'))
        : parseFloat('0'),
      documentType: 'GOODS_REMOVED',
    }

    let result = await createOrder({
      variables: { input },
      refetchQueries: [
        {
          query: WmsOrderListDocument,
          variables: {
            input: {
              searchTerm: '',
              documentType: 'GOODS_RELEASED',
              fetch: {
                cursor: null,
                limit: 40,
              },
            },
          },
        },
        {
          query: WmsGetActiveWarehouseDocument,
          variables: {
            warehouseType: 'RETAIL',
          },
        },
      ],
    })

    appServices
      .handleMutationResult(result, 'Izlaz je uspješno kreiran')
      .onSuccess(async () => {
        if (saveProduct) {
          const listPrice = parseFloat(
            productFormRef?.values?.listPrice
              ? productFormRef?.values?.listPrice?.toString()?.replace(',', '.')
              : '0'
          )

          const purchasePrice = parseFloat(
            productFormRef?.values?.purchasePrice
              ? productFormRef?.values?.purchasePrice
                  ?.toString()
                  .replace(',', '.')
              : '0'
          )

          const listDiscountRate =
            parseFloat(
              productFormRef?.values?.listDiscountRate
                ? productFormRef?.values?.listDiscountRate
                    ?.toString()
                    ?.replace(',', '.')
                : '0'
            ) / 100

          const vatRate = hasVat
            ? parseFloat(productFormRef?.values?.vatRate || '0') / 100
            : 0
          const retailPrice = parseFloat(
            productFormRef?.values?.retailPrice || '0'
          )
          const outQty = parseFloat(productFormRef?.values?.inQty || '1')
          const { vatAmount, baseAmount } = getVatAmounts(retailPrice, vatRate)
          const unitVatAmount = hasVat ? vatAmount : 0
          const unitMargin = hasVat
            ? roundNumberWithTwoDecimals(baseAmount - purchasePrice)
            : roundNumberWithTwoDecimals(retailPrice - purchasePrice)
          const retailMarginRate = hasVat
            ? roundNumberWithTwoDecimals(unitMargin / baseAmount)
            : roundNumberWithTwoDecimals(unitMargin / retailPrice)
          const retailMarginAmount = outQty * unitMargin

          const retailAmount = roundNumberWithTwoDecimals(retailPrice * outQty)
          const purchaseAmount = roundNumberWithTwoDecimals(
            purchasePrice * outQty
          )

          await createItemMutation({
            variables: {
              input: {
                orderId: Number(result?.data?.wmsOrder_create?.id),
                sequence: 1,
                productId: Number(productFormRef?.values?.productId),
                inQty: 0,
                outQty: parseFloat(productFormRef?.values?.inQty),
                uom: productFormRef?.values?.uom,
                listPrice,
                listDiscountRate,
                purchasePrice,
                retailMarginRate,
                retailPrice,
                purchaseAmount,
                retailAmount,
                unitMargin,
                retailMarginAmount,
                vatRate,
                unitVatAmount,
              },
            },
          })
        }
        navigateTo.goodsReleased(params)
      })
  }

  if (createLoading || loadingCreateItem || allWarehouseLoading || orgLoading)
    return <Loader isComponent />

  return (
    <GoodsReceivedForm
      initialValues={{
        orderDate: moment().format('YYYY/MM/DD'),
        refNo: '',
        supplierId: '',
        supplier: null,
        items: [],
        warehouse: allWarehouses?.length === 1 ? allWarehouses[0] : null,
        allWarehouses: allWarehouses,
      }}
      onSubmit={handleSubmit}
    />
  )
}

export default NewGoodsRemoved
